import { Match, Show, Suspense, Switch, createSignal, lazy, onCleanup, onMount, splitProps } from "solid-js";
import { render } from "solid-js/web";
import { ASubmoduleMaterial } from "../../../models";
import { Ctrl, log } from ":mods";
import "./style.css";
import "./vars.css";
import { loadRemoteCss } from "./load-remote-css";
import { loadRemoteIndex } from "./load-remote-index";

const remote_base = "https://backyardcs.github.io/hackyard-frontend-museum-training-static/";
// const remote_base = "http://localhost:5500";
// const remote_base = "https://3fe080ec8d21.ngrok.app/";

const default_root_size = 0.0288;
const root_size_map = {
  // tp07: 0.05,
};
export function ShowMaterialLessonStatic(props: ASubmoduleMaterial & Ctrl.Routes.model.StudentView) {
  const design_name = props.design_name.toLowerCase().split("_");
  const folder = design_name[0];
  // const section = design_name[1];
  const link = `${remote_base}/${folder.toUpperCase()}/lessons/${props.design_name}/`;
  const [$loading, setLoading] = createSignal(true as boolean);
  const [$error, setError] = createSignal(undefined as boolean);
  const [$iframe, set_iframe] = createSignal({
    src: undefined,
    root_font_measure: 0,
    root_font_unit: "px",
  });
  let static_page: Awaited<ReturnType<typeof loadRemoteIndex>> = undefined;
  let ref: HTMLDivElement = undefined;
  let iframe_ref: HTMLIFrameElement = undefined;

  // to adapt to window resizing changes such as zoom in and zoom out
  function updateIframeURLSettings(e: UIEvent) {
    // console.log("event fired :: ", e);
    // const zoomLevel = window.devicePixelRatio;
    const calc = {
      width: ref.clientWidth,
      container_size: ref.clientWidth * (root_size_map[folder] ?? default_root_size),
    };
    const iframe = $iframe();
    const root_font_measure = calc.container_size;
    const root_font_size = calc.container_size + iframe.root_font_unit;
    // console.log("ref client width :: ", calc.width, " :: ");
    const src = link + `index.html?embedded&root_width_full&root_font_size=${root_font_size}`; // 34px
    set_iframe((s) => ({ ...s, root_font_measure, src }));
  }

  onMount(() => {
    // TODO: contact iframe through messages, currently the problem seems that were contacting the container that the iframe is drawn under
    // so we're gonna use plain old link queries :(
    // window.addEventListener("message", (ev) => {
    //   console.log(ev);
    // });
    // ref_iframe.addEventListener("load", () => {
    //   ref_iframe.contentWindow.postMessage({ heelooo: "world!" });
    // });
    loadRemoteIndex(link, "style.css", "vars.css", "styleguide.css", "globals.css")
      .then((obj) => {
        static_page = obj;
        // console.log("el is :: ", static_page.html.body);
        // const frame = document.createElement("iframe");
        // frame.src = link;
        // render(() => frame, ref);
        // console.log("ref is ", ref);
        updateIframeURLSettings(undefined);
        window.addEventListener("resize", updateIframeURLSettings);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching HTML:", error);
        setError(true);
      });
  });
  onCleanup(() => {
    window.removeEventListener("resize", updateIframeURLSettings);
    if (static_page) {
      // static_page.css.removeFromDocumentHead();
    }
  });
  return (
    <div ref={ref} class="relative flex flex-col w-full min-h-full !items-center !justify-center">
      <Show when={!$error()} fallback={<>there's no material lesson for {props.design_name}</>}>
        <Show when={!$loading()} fallback={"loading content"}>
          <iframe ref={iframe_ref} src={$iframe().src} class="w-full min-h-90% items-center justify-center"></iframe>
          <div
            class="button-1 w-fit self-end cursor-pointer mt-2.5 mr-60px"
            onclick={(e) => {
              e.preventDefault();
              props.$.actions.layer.next();
              // console.log("onContinue");
            }}
          >
            <div class="button-1__button-12 h-fit">Continue</div>
          </div>
        </Show>
      </Show>
    </div>
  );
}
